<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted } from '@vue/composition-api';
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig';
import { useLayout } from '@core/layouts/composable/useLayout';
import { useRouter } from '@core/utils';

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue';
import LayoutContentHorizontalAccountantNav from '@/layouts/variants/content/horizontal-nav-accountant/LayoutContentHorizontalNav.vue';
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue';
import LayoutContentVerticalAccountantNav from '@/layouts/variants/content/vertical-nav-accountant/LayoutContentVerticalNav.vue';
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';


// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh';
import store from './store';

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutContentHorizontalAccountantNav,
    LayoutContentVerticalAccountantNav,
    LayoutBlank,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    onMounted(async () => {

      store.state.study_year = JSON.parse(localStorage.getItem("study_year"))

      // localStorage.setItem("studyYearData", JSON.stringify(studyYearData.value))

    })

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') {

        const resultsLocalStorage = JSON.parse(localStorage.getItem('results'))

        const accountType = resultsLocalStorage.account_type

        if (accountType === "manager") {
          return `layout-content-${appContentLayoutNav.value}-nav`
        } else {
          return `layout-content-${appContentLayoutNav.value}-accountant-nav`
        }
      }

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
